import {
  Box,
  Button,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
// import analyticsImage from "./analytics.png";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=905c285f-0358-48c8-8d52-b1f1e5a9c71b&redirectUrl=https://certifiedcode.wixsite.com/analytics/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Place analytics everywhere you want"
                content="Let site visitors easily copy text, such as coupon code, from your site."
                actions={
                  <Box direction="horizontal" gap="SP1">
                    <Button
                      onClick={() => {
                        // window.Intercom("showArticle", 8100023);
                      }}
                    >
                      See how
                    </Button>
                    <Button
                      prefixIcon={<Icons.Reviews />}
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/905c285f-0358-48c8-8d52-b1f1e5a9c71b"
                      target="_blank"
                    >
                      Write a review
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          // image={<Image borderRadius={0} src={analyticsImage} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
